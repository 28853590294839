import React, { Component } from 'react';

import { handlePageChange } from '../utils/adobe';

export default class NoMatch extends Component {
    componenDidMount() {
        handlePageChange({
            page: {
                pageInfo: {
                    pageName: 'exp:experium-pagenotfound',
                }
            },
        });
    }

    render() {
        return <div className='no-match'>
            <h1>Page not found</h1>
        </div>;
    }
}
