import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { SagaProvider } from 'react-async-client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Alert } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';

import { sagaMiddleware } from '../store/middlewares';
import { pushRollbarError } from '../utils/rollbarInit';
import Routes from './Routes';

class App extends Component {
    state = {
        error: false
    };

    componentDidMount() {
        // this.props.appRun();
    }

    componentDidCatch(error, stack) {
        pushRollbarError(error, stack);

        this.setState({ error: true });
    }

    render() {
        const { store } = this.props;

        return this.state.error ? (
            <div>
                <Alert type='error' message='Не удалось запустить приложение' />
            </div>
        ) : (
            <SagaProvider sagaMiddleware={sagaMiddleware}>
                <Provider store={store}>
                    <ConfigProvider locale={ruRu}>
                        <BrowserRouter>
                            <Routes />
                        </BrowserRouter>
                    </ConfigProvider>
                </Provider>
            </SagaProvider>
        );
    }
}

export default App;
