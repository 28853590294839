import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { assocPath, compose, prop, path, pathOr, propEq, find, trim } from 'ramda';
import Form from '@experium/findy-form';
import { Spin, Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { CAREER_DOMAIN } from '../../constants/domain';
import { TRANSLATIONS } from '../../constants/translation';
import { getHtmlOpd, getOpdValues } from '../../utils/opd';
import { getVacancyId, getVacancyAccentureId, getCountryCode } from '../../utils/params';
import { candidateID, handlePageChange, handleFormError, handleFormSuccess } from '../../utils/adobe';
import { getVacancy, getDomain, postApplicant } from '../../actions/asyncActions';
import NetworkError from '../NetworkError';
import logo from "../../assets/img/logo-icon.png";

const getDomainId = compose(
    prop('id'),
    find(propEq('name', CAREER_DOMAIN)),
    pathOr([], ['data', '_relations', 'domains']),
);

class CareerLanding extends Component {
    onSubmit = values => this.props.postApplicant.dispatch({
      ...values,
      MobilePhone: trim(values.MobilePhone || '').replace(/[^+\d]/g, ''),
    }, ({
        id: path(['data', 'id'], this.props.getVacancy),
        domain: getDomainId(this.props.getVacancy)
    }));

    render() {
        const { postApplicant, postApplicant: { meta: postApplicantMeta }, getVacancy: { data, meta }, getDomain: { data : domainData, meta : domainMeta } } = this.props;
        const allowFileExtensions = path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], data);
        const htmlOpd = getHtmlOpd(getCountryCode(this.props));

        return meta.error || domainMeta.error ? (
            <NetworkError error={meta.error || domainMeta.error} />
        ) : (
            <div className="wrap-sidebar wrap-career">
                { (meta.pending || domainMeta.pending) && (
                    <Spin />
                )}
                { postApplicant.meta.success ? (
                    <h1>Application success</h1>
                ) : domainMeta.success && (
                  <Fragment>
                    <div className="head-box">
                      <Row>
                        <Col className='text-box' xs={24} lg={12}>
                          <div>
                            <h1><span className="purple">CAREER</span><br /> AT ACCENTURE</h1>
                            <div className="desc">
                              YOU ARE APPLYING FOR<br />
                              {path(['title'], data)} ROLE
                            </div>
                          </div>
                        </Col>
                        <Col className='logo-box' xs={24} lg={12}>
                          <img src={logo} alt=""/>
                        </Col>
                      </Row>
                    </div>
                    <Form
                        fields={compose(
                            assocPath([1, 'settings', 'tree'], true),
                            assocPath([1, 'settings', 'parents'], {
                                [path(['_relations', 'vacancyFormPreset', 'questions', 0, 'field'], domainData)]: path(['_relations', 'vacancyFormPreset', 'questions', 0, 'settings', 'dictionary'], domainData),
                            }),
                            assocPath([5, 'translations', 'label', 'en'], 'Where do you live'),
                            assocPath([6, 'translations', 'label', 'en'], 'Email'),
                            assocPath([8, 'translations', 'label', 'en'], 'Mobile Number'),
                            assocPath([8, 'extra'], (
                                <Tooltip placement="left" className='form-extra' title="Please provide your phone numbers after contry code (for example 999 999 99 99). ">
                                    <InfoCircleOutlined />
                                </Tooltip>
                            )),
                            assocPath([8, 'settings', 'mask'], '99999999999999999999'),
                            assocPath([11, 'translations', 'label', 'en'], 'Your most recent employer'),
                            assocPath([10, 'translations', 'label', 'en'], 'Have you previously been employed by Accenture?'),
                            assocPath([1, 'translations', 'label', 'en'], 'How did you learn about this role?'),
                            assocPath([15, 'translations', 'label', 'en'], 'Attach your resume'),
                            pathOr([], ['_relations', 'vacancyFormPreset', 'questions']),
                        )(domainData)}
                        formRender={this.formRender}
                        onSubmit={this.onSubmit}
                        onSubmitFail={() => handleFormError('career', 'validation')}
                        opd={pathOr(data.pda, ['_relations', 'company', 'companySettings', 'pda'], data)}
                        opdSubmitDisabled={false}
                        language={'en'}
                        postFileUrl={`/api/landing/public/file/${data.id}`}
                        getFileUrl={id => `${process.env.REACT_APP_LOCAL_FILEURL || window.location.origin}/api/file/${id}`}
                        htmlOpd={htmlOpd}
                        serverErrors={postApplicant.meta.error}
                        submitting={postApplicantMeta.pending}
                        allowFileExtensions={allowFileExtensions}
                        getOpdValues={getOpdValues}
                        translations={TRANSLATIONS}
                        htmlAttrs={{
                            submit: {
                                'data-analytics-link-name': 'submit',
                                'data-analytics-content-type': 'cta',
                                'data-analytics-template-zone': 'body',
                                'data-analytics-module-name': 'application submission',
                            },
                            opdCheckbox: {
                                'data-analytics-link-name': 'i accept the data privacy policy',
                                'data-analytics-content-type': 'cta',
                                'data-analytics-template-zone': 'body',
                                'data-analytics-module-name': 'application submission',
                            },
                            opdAccept: {
                                'data-analytics-link-name': 'accept',
                                'data-analytics-content-type': 'cta',
                                'data-analytics-template-zone': 'body',
                                'data-analytics-module-name': 'data privacy policy overlay',
                            },
                            opdClose: {
                                'data-analytics-link-name': 'close',
                                'data-analytics-content-type': 'cta',
                                'data-analytics-template-zone': 'body',
                                'data-analytics-module-name': 'data privacy policy overlay',
                            }
                        }}
                    />
                  </Fragment>
                )}
            </div>
        );
    }

    formRender = ({ fields, renderField }) => {
        return (
            <div className="form-box">
                <div className="box">
                  <Row>
                    <Col span={24}>
                      <h2>Personal Information<small className="text-required">All fields are required</small></h2>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12}>
                      {renderField(fields[3])}
                    </Col>
                    <Col xs={24} lg={12}>
                      {renderField(fields[2])}
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12}>
                      {renderField(fields[9])}
                    </Col>
                  </Row>
                </div>
                <div className="box gray-box">
                  <Row>
                    <Col span={24}>
                      <h2>Contact details<small className="text-required">All fields are required</small></h2>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={24}>
                      {renderField(fields[6])}
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12}>
                      {renderField(fields[7])}
                    </Col>
                    <Col xs={24} lg={12}>
                      {renderField(fields[8])}
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12} id='place'>
                      {renderField(fields[5])}
                    </Col>
                    <Col xs={24} lg={12}>
                      {renderField(fields[4])}
                    </Col>
                  </Row>
                </div>
                <div className="box">
                  <Row>
                    <Col span={24}>
                      <h2>Education<small className="text-required">All fields are required</small></h2>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12}>
                      {renderField(fields[12])}
                      {renderField(fields[14])}
                    </Col>
                    <Col xs={24} lg={12}>
                      {renderField(fields[13])}
                    </Col>
                  </Row>
                </div>
                <div className="box gray-box">
                  <Row>
                    <Col span={24}>
                      <h2>Work experience<small className="text-required">All fields are required</small></h2>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12}>
                      {renderField(fields[11])}
                    </Col>
                    <Col xs={24} lg={12}>
                      {renderField(fields[10])}
                    </Col>
                  </Row>
                </div>
                <div className="box">
                  <Row>
                    <Col span={24}>
                      <h2>Other<small className="text-required">All fields are required</small></h2>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                    <Col xs={24} lg={12}>
                      <div className="file-wrap">
                        {renderField(fields[1])}
                        {renderField(fields[15])}
                      </div>
                    </Col>
                    <Col className="long-name-fix" xs={24} lg={12}>
                      {renderField(fields[16])}
                    </Col>
                  </Row>
                </div>
                {renderField(fields[17])}
            </div>
        );
    }
}

export default withAsyncActions({
    postApplicant: postApplicant
        .withOptions()
        .withSuccessHandler(({ history, location}) => {
            handleFormSuccess('career');
            handlePageChange({
                page: {
                    pageInfo: {
                        pageName: 'exp:experium-careersite-success',
                    }
                },
            });
            history.push(`${location.pathname}/success`.replace('//', '/'));
        })
        .withErrorHandler(() => {
            handleFormError('career', 'request');
        }),
    getVacancy: getVacancy
        .withPayload(getVacancyId)
        .withOptions({ dispatchOnMount: true })
        .withSuccessHandler(props => {
          handlePageChange({
              page: {
                  pageInfo: {
                      pageName: 'exp:experium-careersite',
                  }
              },
              job: {
                  jobID: getVacancyAccentureId(props),
                  jobTitle: path(['data', 'title'], props.getVacancy)
              },
              user: {
                  candidateID,
              }
          });
        }),
    getDomain: getDomain
        .withPayload(({ getVacancy }) => ({
            company: pathOr('accenture', ['data', 'company'], getVacancy),
            domain: getDomainId(getVacancy) || 'error',
        }))
        .withOptions({ dispatchOnUpdate: true })
})(CareerLanding);
