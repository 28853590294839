import { createAsyncAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';
import * as handlers from './asyncHandlers';

export const getVacancy = createAsyncAction(actions.GET_VACANCY, handlers.getVacancyHandler);
export const getDomain = createAsyncAction(actions.GET_DOMAIN, handlers.getDomainHandler);
export const postApplicant = createAsyncAction(actions.POST_APPLICANT, handlers.postApplicantHandler);
export const postReferal = createAsyncAction(actions.POST_REFERAL, handlers.postReferalHandler);
export const getReferal = createAsyncAction(actions.GET_REFERAL, handlers.getReferalHandler);
export const postReferalApplicant = createAsyncAction(actions.POST_REFERAL_APPLICANT, handlers.postReferalApplicantHandler);
