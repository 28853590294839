import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Spin, Input, Form as FormComponent, Row, Col, Radio } from 'antd';
import { assocPath, compose, prop, path, pathOr, propEq, find, isNil } from 'ramda';
import Form from '@experium/findy-form';

import { REFERRAL_DOMAIN } from '../../constants/domain';
import { TRANSLATIONS } from '../../constants/translation';
import { getHtmlOpd, getOpdValues } from '../../utils/opd';
import { getCountryCode, getVacancyAccentureId } from '../../utils/params';
import { candidateID, handlePageChange, handleFormError, handleFormSuccess } from '../../utils/adobe';
import { getVacancy, getDomain, getReferal, postReferalApplicant } from '../../actions/asyncActions';
import NetworkError from '../NetworkError';

import logo from '../../assets/img/logo-icon.png';

class RecommendedLanding extends Component {
  state = {
    value: null,
    accepted: null,
  };

  onChangeValue = ({ target: { value } }) => this.setState({ value });

  setAccepted = () => {
    if (this.state.value === 'next') {
      this.setState({ accepted: true });
      window.scrollTo(0, 0);
    } else {
      window.location.href = 'https://www.accenture.com/ru-ru/careers/jobsearch?jk=&sb=1';
    }
  }

  onSubmit = values => this.props.postReferalApplicant.dispatch(values, ({
    token: path(['match', 'params', 'token'], this.props),
  }));

  render() {
    const { accepted, value } = this.state;
    const { postReferalApplicant, getReferal: { data : referal, meta : referalMeta }, getVacancy: { data, meta }, getDomain: { data : domainData, meta : domainMeta } } = this.props;
    const allowFileExtensions = path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], data);
    const htmlOpd = getHtmlOpd(getCountryCode({ location: { search: referal.search } }));

    if (!referalMeta.success || !meta.success || !domainMeta.success) {
      return referalMeta.error || domainMeta.error || meta.error ? (
          <NetworkError error={referalMeta.error || domainMeta.error || meta.error} isLink={referalMeta.error} />
      ) : (
          <div className="load-box"><Spin /></div>
      );
    }

    return <Fragment>
      { isNil(accepted) && !meta.success ? null : accepted ?
          <Fragment>
            { postReferalApplicant.meta.success ? (
                <div className="head-box">
                  <Row>
                    <Col className='text-box' xs={24} lg={12}>
                      <div>
                        <h1>Thank you!</h1>
                        <div className="desc">We&nbsp;now have all details for the application. We&nbsp;will be&nbsp;in&nbsp;touch if&nbsp;we&nbsp;need more information about you. Keep an&nbsp;eye on&nbsp;your mailbox. <br/><br/>Respectfully,
                          Accenture Recruiting <br/><br/>
                          Visit us on <a href="https://www.accenture.com/ru-ru">accenture.com</a>
                        </div>
                      </div>
                    </Col>
                    <Col className='logo-box' xs={24} lg={12}>
                      <img src={logo} alt=""/>
                    </Col>
                  </Row>
                </div>
            ) : domainMeta.success && (
                <div>
                  <div className="head-box">
                    <Row>
                      <Col className='text-box' xs={24} lg={12}>
                        <div>
                          <h1><span className="purple">CAREER OPPORTUNITIES</span><br/>AT ACCENTURE</h1>
                          <div className="desc">Provide us with some more details to finalize your application</div>
                        </div>
                      </Col>
                      <Col className='logo-box' xs={24} lg={12}>
                        <img src={logo} alt=""/>
                      </Col>
                    </Row>
                  </div>
                  <Fragment>
                    <div>
                      <div className='box gray-box'>
                        <Row>
                          <Col span={24}>
                            <h2>You have been referred by</h2>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                          <Col xs={24} lg={12}>
                            <FormComponent.Item
                                label={'First Name'}
                                labelCol={{ span: 24 }}>
                              <Input value={path(['form', 'FirstNameAccenture'], referal)} disabled />
                            </FormComponent.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormComponent.Item
                                label={'Last Name'}
                                labelCol={{ span: 24 }}>
                              <Input value={path(['form', 'LastNameAccenture'], referal)} disabled />
                            </FormComponent.Item>
                          </Col>
                        </Row>
                        <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                          <Col xs={24} lg={12}>
                            <FormComponent.Item
                                label={'Accenture email'}
                                labelCol={{ span: 24 }}>
                              <Input value={path(['form', 'EmailAccenture'], referal)} disabled />
                            </FormComponent.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Form
                        initialValues={path(['form'], referal)}
                        fields={compose(
                            assocPath([7, 'translations', 'label', 'en'], 'Where do you live'),
                            pathOr([], ['_relations', 'vacancyFormPreset', 'questions']),
                        )(domainData)}
                        formRender={this.formRender}
                        onSubmit={this.onSubmit}
                        onSubmitFail={() => handleFormError('recommended', 'validation')}
                        opd={pathOr(data.pda, ['_relations', 'company', 'companySettings', 'pda'], data)}
                        opdSubmitDisabled={false}
                        language={'en'}
                        postFileUrl={`/api/landing/public/file/${data.id}`}
                        getFileUrl={id => `${process.env.REACT_APP_LOCAL_FILEURL || window.location.origin}/api/file/${id}`}
                        htmlOpd={htmlOpd}
                        serverErrors={postReferalApplicant.meta.error}
                        submitting={postReferalApplicant.meta.pending}
                        allowFileExtensions={allowFileExtensions}
                        getOpdValues={getOpdValues}
                        translations={TRANSLATIONS}
                        htmlAttrs={{
                          submit: {
                            'data-analytics-link-name': 'submit',
                            'data-analytics-content-type': 'cta',
                            'data-analytics-template-zone': 'body',
                            'data-analytics-module-name': 'referral application submission',
                          },
                          opdCheckbox: {
                            'data-analytics-link-name': 'i accept the data privacy policy',
                            'data-analytics-content-type': 'cta',
                            'data-analytics-template-zone': 'body',
                            'data-analytics-module-name': 'referral application submission',
                          },
                          opdAccept: {
                            'data-analytics-link-name': 'accept',
                            'data-analytics-content-type': 'cta',
                            'data-analytics-template-zone': 'body',
                            'data-analytics-module-name': 'data privacy policy overlay',
                          },
                          opdClose: {
                            'data-analytics-link-name': 'close',
                            'data-analytics-content-type': 'cta',
                            'data-analytics-template-zone': 'body',
                            'data-analytics-module-name': 'data privacy policy overlay',
                          }
                        }}
                    />
                  </Fragment>
                </div>
            )}
          </Fragment> :
          <div>
            <div className="head-box">
              <Row>
                <Col className='text-box' xs={24} lg={12}>
                  <div>
                    <h1>Hi, {path(['form', 'firstName'], referal)}</h1>
                    <div className="desc">
                      {path(['form', 'LastNameAccenture'], referal)} {path(['form', 'FirstNameAccenture'], referal)} has referred you for {path(['title'], data)} role at Accenture.
                    </div>
                    <br/>
                    <div>
                      <div className="text-required">We need your approval in order to proceed with your application.</div>
                    </div>
                  </div>
                </Col>
                <Col className='logo-box' xs={24} lg={12}>
                  <img src={logo} alt=""/>
                </Col>
              </Row>
              <div className="box small-box">
                <label>Select the appropriate statement below</label>
                <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                  <Col xs={24} lg={24}>
                    <Radio.Group
                        options={[
                          { label: 'I agree to be referred to Accenture', value: 'next' },
                          { label: 'I do not agree to be referred to Accenture', value: 'dont' },
                        ]}
                        onChange={this.onChangeValue}
                        value={value}
                        optionType="button"
                    />
                  </Col>
                </Row>
              </div>
              <div className="box small-box">
                <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                  <Col xs={24} lg={12}>
                    <button
                        className='index__formBtn'
                        disabled={!value}
                        type='primary'
                        onClick={() => this.setAccepted()}
                        data-analytics-link-name="next"
                        data-analytics-content-type="nav/paginate"
                        data-analytics-template-zone="body"
                        data-analytics-module-name="experium-module"
                    >Next</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
      }
    </Fragment>;
  }

  formRender = ({ fields, renderField }) => (
      <div className="box last-box">
        <Row>
          <Col span={24}>
            <h2>Please review and complete<small className="text-required">All fields are required</small></h2>
          </Col>
        </Row>
        <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
          <Col xs={24} lg={12}>
            {renderField(fields[6])}
          </Col>
          <Col xs={24} lg={12}>
            {renderField(fields[5])}
          </Col>
        </Row>
        <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
          <Col xs={24} lg={12}>
            {renderField(fields[4])}
          </Col>
        </Row>
        <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
          <Col xs={24} lg={12} id='place'>
            {renderField(fields[7])}
          </Col>
          <Col xs={24} lg={12}>
            {renderField(fields[8])}
          </Col>
        </Row>
        <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
          <Col xs={24} lg={12}>
            <div className="file-wrap">
              {renderField(fields[9])}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {renderField(fields[10])}
          </Col>
        </Row>
      </div>
  )
}
export default withAsyncActions({
  postReferalApplicant: postReferalApplicant
      .withOptions()
      .withSuccessHandler(({ history, location }) => {
        handleFormSuccess('recommended');
        handlePageChange({
          page: {
            pageInfo: {
              pageName: 'exp:experium-referral-success',
            }
          },
        });
        history.push(`${location.pathname}/success`.replace('//', '/'));
      })
      .withErrorHandler(() => {
        handleFormError('recommended', 'request');
      }),
  getReferal: getReferal
      .withPayload(path(['match', 'params', 'token']))
      .withOptions({ dispatchOnMount: true }),
  getVacancy: getVacancy
      .withPayload(getVacancyAccentureId)
      .withSuccessHandler(props => {
        handlePageChange({
          page: {
            pageInfo: {
              pageName: 'exp:experium-referral',
            }
          },
          job: {
            jobID: pathOr('', ['data', 'code'], props.getVacancy).replace('accenture-', ''),
            jobTitle: path(['data', 'title'], props.getVacancy)
          },
          user: {
            candidateID,
          }
        });
      })
      .withOptions({ dispatchOnMount: true }),
  getDomain: getDomain
      .withPayload(({ getVacancy }) => ({
        company: pathOr('accenture', ['data', 'company'], getVacancy),
        domain: compose(
            prop('id'),
            find(propEq('name', REFERRAL_DOMAIN)),
            pathOr([], ['data', '_relations', 'domains']),
        )(getVacancy)
      }))
      .withOptions({ dispatchOnUpdate: true })
})(RecommendedLanding);
