import React, { Component } from 'react';
import { Layout } from 'antd';

import ScrollToTop from './ScrollToTop';
import logo from '../assets/img/logo.png';
import logoIcon from '../assets/img/logo-footer.png';

const { Header, Content, Footer } = Layout;

export default class LayoutComponent extends Component {
    render() {
        return (
            <Layout className='layout'>
                <Header>
                  <div className="container">
                    <div className='logo'>
                      <a
                          href="https://www.accenture.com/ru-ru"
                          target="_blank"
                          rel="noopener noreferrer"
                          data-analytics-link-name="accenture site"
                          data-analytics-content-type="header"
                          data-analytics-content-class="content"
                          data-analytics-template-zone="header"
                          data-analytics-component-name="header"
                          data-analytics-module-name="header"
                          data-analytics-link-type="header"
                          data-analytics-engagement="false"
                      ><img src={logo} alt="logo"/></a>
                    </div>
                  </div>
                </Header>
                <Content>
                  <div className="container">
                    <ScrollToTop>
                        <div className='site-layout-content'>{this.props.children}</div>
                    </ScrollToTop>
                  </div>
                </Content>
                <Footer>
                  <div className="container">
                    <div className="row">
                      <div className="menu-box">
                        <a
                          href="https://www.accenture.com/ru-ru"
                          data-analytics-link-name="accenture"
                          data-analytics-content-type="footer"
                          data-analytics-template-zone="footer"
                          data-analytics-module-name="globalfootersettings"
                        >
                          <img className="logo-icon" src={logoIcon} alt=""/>
                        </a>
                        <ul className="menu">
                          <li>
                            <a
                              href="https://www.accenture.com/ru-ru/about/company-index"
                              data-analytics-link-name="об accenture"
                              data-analytics-content-type="footer"
                              data-analytics-template-zone="footer"
                              data-analytics-module-name="globalfootersettings"
                            >Об&nbsp;Accenture</a>
                          </li>
                          <li>
                            <a
                              href="https://www.accenture.com/ru-ru/about/contact-us"
                              data-analytics-link-name="контакты"
                              data-analytics-content-type="footer"
                              data-analytics-template-zone="footer"
                              data-analytics-module-name="globalfootersettings"
                            >Контакты</a>
                          </li>
                          <li>
                            <a
                              href="https://www.accenturealumni.com/web/guest/home"
                              data-analytics-link-name="alumni"
                              data-analytics-content-type="footer"
                              data-analytics-template-zone="footer"
                              data-analytics-module-name="globalfootersettings"
                            >Alumni</a>
                          </li>
                          <li>
                            <a
                              href="https://www.accenture.com/ru-ru/about/privacy-policy"
                              data-analytics-link-name="политика конфиденциальности"
                              data-analytics-content-type="footer"
                              data-analytics-template-zone="footer"
                              data-analytics-module-name="globalfootersettings"
                            >ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ </a>
                          </li>
                        </ul>
                      </div>
                      <ul className="social">
                        <li>
                          <a
                            href="https://www.linkedin.com/groups/Accenture-in-Russia-1842483/about"
                            data-analytics-link-name="linkedin"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >
                            <span className="ic in" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/Accenture"
                            data-analytics-link-name="twitter"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >
                            <span className="ic tw" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/AccentureRussia"
                            data-analytics-link-name="facebook"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >
                            <span className="ic fb" />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/user/accentureRUScareers"
                            data-analytics-link-name="youtube"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >
                            <span className="ic yt" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <ul className="menu2">
                        <li>
                          <a
                            href="https://www.accenture.com/_acnmedia/Accenture/Redesign-Assets/DotCom/Documents/Local/1/Accenture-Terms-of-Personnel-Reserve.pdf"
                            data-analytics-link-name="положение о кадровом резерве"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >Положение о кадровом резерве</a>
                        </li>
                        <li>
                          <a
                            href="https://www.accenture.com/ru-ru/support/terms-of-use"
                            data-analytics-link-name="условия использования"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >Условия использования</a>
                        </li>
                        <li>
                          <a
                            href="https://www.accenture.com/ru-ru/support/company-cookies-similar-technology"
                            data-analytics-link-name="политика в отношении файлов cookie"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >Политика в отношении файлов Cookie</a>
                        </li>
                        <li>
                          <a
                            href="https://www.accenture.com/ru-ru/accessibility-statement"
                            data-analytics-link-name="положение о доступе"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                            rel="noopener noreferrer"
                            target="_blank"
                          >Положение о доступе</a>
                        </li>
                        <li>
                          <a
                            href="https://www.accenture.com/ru-ru/site-map"
                            data-analytics-link-name="карта сайта"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >Карта сайта</a>
                        </li>
                        <li>
                          <a
                            href="https://www.accenture.com/us-en/about/global-meritocracy"
                            data-analytics-link-name="global meritocracy"
                            data-analytics-content-type="footer"
                            data-analytics-template-zone="footer"
                            data-analytics-module-name="globalfootersettings"
                          >Global Meritocracy</a>
                        </li>
                      </ul>
                      <span className="copy">©  2020 Accenture Все права защищены.</span>
                    </div>
                  </div>
                </Footer>
            </Layout>
        );
    }
}
