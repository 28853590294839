import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const VACANCY = construct('/public/vacancy/:id');
export const DOMAIN = construct('/public/company/:company/domains/:domain');
export const APPLICANT = construct('/response/:id/:domain');
export const REFERAL = construct('/referal/:id/:domain');
export const REFERAL_CHECK = construct('/referal/check/:token');
export const REFERAL_APPLICANT = construct('/referal/approve/:token');

