import axios from 'axios';

import * as urls from '../constants/urls';

export const getVacancyHandler = ({ payload }) =>
    axios.get(urls.VACANCY.stringify({ id: payload }), { params: {
        relations: ['company', 'domains', 'domains.vacancyFormPreset'],
    } });

export const getDomainHandler = ({ payload }) =>
    axios.get(urls.DOMAIN.stringify(payload), { params: {
        relations: ['company', 'vacancyFormPreset'],
    } });

export const postApplicantHandler = ({ payload, attrs }) => (
    axios.post(urls.APPLICANT.stringify(attrs), payload)
);

export const postReferalHandler = ({ payload, attrs }) => (
    axios.post(urls.REFERAL.stringify(attrs) + attrs.search, payload)
);

export const getReferalHandler = ({ payload }) => axios.get(urls.REFERAL_CHECK.stringify({ token: payload }));

export const postReferalApplicantHandler = ({ payload, attrs }) => (
    axios.post(urls.REFERAL_APPLICANT.stringify(attrs), payload)
);
