import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Row, Col } from 'antd';

import { getVacancyId } from '../../utils/params';
import { getVacancy } from '../../actions/asyncActions';
import withSuccessPage from '../hocs/withSuccessPage';

import logo from '../../assets/img/logo-icon.png';

class ApplicationSuccess extends Component {
    render() {
        return (
            <div className="wrap-sidebar">
                <div className="head-box">
                    <Row>
                        <Col className='text-box' xs={24} lg={12}>
                            <div>
                                <h1><span className="purple">Thank you</span><br /> for completing<br /> your application!</h1>
                                <div className="desc">
                                    Should we need more information, we will be in touch.
                                </div>
                            </div>
                            <br />
                            <div className="text-required">
                                Respectfully,<br/> Accenture Recruiting
                            </div>
                            <br/>
                            <br/>
                            <div>Visit us on <a
                                href="https://accenture.com/ru-ru"
                                data-analytics-link-name="accenture.com"
                                data-analytics-content-type="nav/paginate"
                                data-analytics-template-zone="body"
                                data-analytics-module-name="experium-module"
                            >accenture.com</a></div>
                        </Col>
                        <Col className='logo-box' xs={24} lg={12}>
                            <img src={logo} alt=""/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withSuccessPage(withAsyncActions({
    getVacancy: getVacancy
        .withPayload(getVacancyId)
})(ApplicationSuccess));
