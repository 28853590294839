import { prop, trim } from 'ramda';

const createHtmlOpd = opd =>
    `<div>
        <style>
          p {
            line-height: 28px;
            font-size: 14px;
          }
          a {
              color: #7500C0;
          }
          .checkbox-inner {
            min-width: 20px;
            margin-right: 10px;
            border: 1px solid #000000;
            width: 20px;
            height: 20px;
            border-radius: 0;
            position: relative;
            cursor: pointer;
            top: 4px;
            left: 0;
            display: inline-block;
            background-color: #ffffff;
          }
          .submitted .required .checkbox-inner {
            border-color: red;
          }
          .submitted .required input:checked + .checkbox-inner {
            border-color: #000;
          }
          .checkbox-wrap {
            display: flex;
            position: relative;
          }
          .checkbox-wrap.required .text-label:before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 18px;
            line-height: 1;
            content: "*";
          }
          .checkbox-wrap input:checked + .checkbox-inner {
            background-color: #000000;
          }
          .checkbox-wrap input {
            position: absolute!important;
            left: 0;
            z-index: 9999;
            cursor: pointer;
            opacity: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
          .checkbox-wrap input:checked + .checkbox-inner:before {
            content: '';
            left: 6px;
            top: 2px;
            width: 6px;
            height: 10px;
            transform: rotate(45deg);
            position: absolute;
            display: table;
            border: 2px solid #ffffff;
            border-top: 0;
            border-left: 0;
          }
          .text-label {
            font-weight: normal;
            font-size: 15px;
            cursor: pointer;
          }
          form + button.index__formBtn {
            background: #000;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            box-shadow: 6px 6px 0 #A7A7A7;
            padding: 0 40px;
            min-height: 40px;
          }
          .name, .email {
              width: 400px;
          }
        </style>
        <strong>
            <div style="text-align: center; font-size: 24px;">СОГЛАСИЕ КАНДИДАТА</div>
            <div style="text-align: center; font-size: 16px; margin: 10px 0 24px;">на обработку персоных данных</div>
        </strong>
        <p>Я, <input class="name text" name="name" type="text" placeholder="Фамилия Имя" required />, гражданин <input class="text" name="country" type="text" placeholder="страна" required />, личный емейл <input class="text email" name="email" type="email" placeholder="личный email" required /> (далее «Кандидат»), даю согласие ${opd.company} (далее «<strong>Компания</strong>»), на обработку моих персональных данных, указанных в таблице ниже, в соответствии с ${opd.act}</p>
        <table style="border-collapse: collapse; border: 1px solid black; margin-bottom: 20px;">
            <tr style="border: 1px solid black;">
                <th style="padding: 5px;">Перечень обрабатываемых персональных данных</th>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Фамилия, имя, отчество</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Дата и место рождения</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Реквизиты документа, удостоверяющего личность${opd.snils || ''} (для кандидатов, в отношении которых будет проведена проверка и/или дальнейшее трудоустройство)</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Пол</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Гражданство</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Место регистрации и фактического проживания</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Номера телефонов (мобильный, домашний)</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Адрес электронной почты</td>
            </tr>
            <tr style="border: 1px solid black;">
                <td style="padding: 5px;">Изображение, используемое в резюме по усмотрению Кандидата</td>
            </tr>
        </table>
        <p>Для вышеуказанных целей разрешаю осуществлять следующие действия как автоматизированным, так и неавтоматизированным способом: сбор, запись, хранение, систематизация, накопление, уточнение (обновление, изменение), использования, копирование, обезличивание, комбинирование, блокирование, уничтожение и иные способы обработки, а также осуществлять трансграничную передачу аффилированным лицам Компании (список и адреса компаний доступны по ссылке <a data-analytics-link-name="https://www.sec.gov/Archives/edgar/data/1467373/000146737318000318/acn831201810-kexhibit211.htm" data-analytics-content-type="cta" data-analytics-template-zone="body" data-analytics-module-name="data privacy policy overlay" href="https://www.sec.gov/Archives/edgar/data/1467373/000146737318000318/acn831201810-kexhibit211.htm" target="_blank">https://www.sec.gov/Archives/edgar/data/1467373/000146737318000318/acn831201810-kexhibit211.htm</a>), а также передачу в организацию, осуществляющую обработку персональных данных по поручению Компании: ${opd.supportCompany} для подготовки и оформления трудового договора с Кандидатом и проверки достоверности предоставленных данных.</p>
        <p>Срок обработки персональных данных Кандидатов, включенных в кадровый резерв, составляет ${opd.processingTime}. Настоящее согласие может быть отозвано путем подачи письменного заявления.</p>
        ${opd.processingConditions || ''}
        <p style="margin-top: 30px;"><label class="checkbox-wrap required"><input name="opdPurpose" type="checkbox" data-separate-field="opdPurpose" required /><span class="checkbox-inner"></span><span class="text-label">я выражаю согласие на обработку персональных данных для цели рассмотрения вопроса о моем трудоустройстве в Компании и включения в кадровый резерв Компании</span></label></p>
        <p><label class="checkbox-wrap required"><input name="opdAccept" type="checkbox" data-separate-field="opdAccept" required /><span class="checkbox-inner"></span><span class="text-label">я выражаю согласие на передачу моих персональных данных в организацию, осуществляющую обработку персональных данных по поручению Компании: ${opd.supportCompany} для подготовки и оформления трудового договора с Кандидатом и проверки достоверности предоставленных данных</span></label></p>
        <p><label class="checkbox-wrap required"><input name="transmission" type="checkbox" data-separate-field="transmission" required /><span class="checkbox-inner"></span><span class="text-label">я выражаю согласие на трансграничную передачу моих персональных данных в другие компании группы Аксенчер</span></label></p>
        <p style="margin-bottom: 30px;"><label class="checkbox-wrap"><input name="mailing" type="checkbox" data-separate-field="mailing" /><span class="checkbox-inner"></span><span class="text-label">я выражаю согласие на получение рассылки материалов рекламного и/или информационного характера посредством SMS-сервисов, Viber, WhatsApp, Telegram, Skype и других месcенджеров, электронной почты и т.д.</span></label></p>
    </div>`
;

const opdRU = {
    company: '<strong>Обществу с ограниченной ответственностью «АКСЕНЧЕР»</strong>, ИНН 7705476338 ОГРН 1027705028405, находящемуся по адресу РФ, 115054, г. Москва, Павелецкая площадь д. 2, строение 2',
    snils: ', СНИЛС/ИНН ',
    act: 'Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных» и иного применимого законодательства.',
    supportCompany: '<strong>ООО «Аксор»</strong> (191186, г. Санкт-Петербург, проспект Невский, д. 10 литера. А)',
    processingTime: '6 лет',
    processingConditions: '<p>Условия обработки Компанией персональных данных и иные применимые положения закреплены в Положении Компании о персональных данных (электронная версия доступна по ссылке <a data-analytics-link-name="https://www.accenture.com/_acnmedia/Accenture/ru-ru/PDF/Position_of_personal_data.pdf" data-analytics-content-type="cta" data-analytics-template-zone="body" data-analytics-module-name="data privacy policy overlay" href="https://www.accenture.com/_acnmedia/Accenture/ru-ru/PDF/Position_of_personal_data.pdf" target="_blank">https://www.accenture.com/_acnmedia/Accenture/ru-ru/PDF/Position_of_personal_data.pdf</a>) и Положении Компании о кадровом резерве (электронная версия доступна по ссылке <a data-analytics-link-name="https://www.accenture.com/_acnmedia/Accenture/Redesign-Assets/DotCom/Documents/Local/1/Accenture-Terms-of-Personnel-Reserve.pdf" data-analytics-content-type="cta" data-analytics-template-zone="body" data-analytics-module-name="data privacy policy overlay" href="https://www.accenture.com/_acnmedia/Accenture/Redesign-Assets/DotCom/Documents/Local/1/Accenture-Terms-of-Personnel-Reserve.pdf" target="_blank">https://www.accenture.com/_acnmedia/Accenture/Redesign-Assets/DotCom/Documents/Local/1/Accenture-Terms-of-Personnel-Reserve.pdf</a>).</p>'
};

const opdKZ = {
    company: '<strong>Филиалу Закрытой акционерной компании с ограниченной ответственностью «Аксенчер Текнолоджи Венчурз Б. В.» в Республике Казахстан</strong>, БИН 100641019943, находящемуся по адресу 050040, Республика Казахстан, город Алматы, Бостандыкский район, Проспект Аль-Фараби, дом 77/7, н.п. 10а',
    act: 'Законом Республики Казахстан от 21 мая 2013 года N 94-V “О персональных данных и их защите” и иным применимым законодательством.',
    supportCompany: '<strong>ТОО «Интеркомп Казахстан»</strong> (г. Алматы, пр. Абая, уг. ул. Байзакова, 52)',
    processingTime: '3 года'
};

export const regionsOpd = {
    'RU': createHtmlOpd(opdRU),
    'KZ': createHtmlOpd(opdKZ),
};

export const getHtmlOpd = region => regionsOpd[(`${region}`).toUpperCase()] || regionsOpd.RU;

export const getOpdValues = ({ values }) => {
  const country = prop('innerHTML', document.querySelector('#place .jobot-forms-rc-select-selection-item'));

  return {
      name: trim(`${values.firstName || ''} ${values.lastName || ''}`),
      email: values.email,
      country,
  };
};
