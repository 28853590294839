import { compose, path, prop } from 'ramda';
import qs from 'qs';

const parseProps = props => qs.parse(`${path(['location', 'search'], props)}`, { ignoreQueryPrefix: true } );

export const getVacancyAccentureId = path(['match', 'params', 'id']);

export const getVacancyId = compose(
    id => `accenture-${id}`,
    getVacancyAccentureId,
);


export const getCountryCode = compose(
    prop('country'),
    parseProps,
);
