import React from 'react';
import { prop } from 'ramda';

import { getNetworkErrorText } from '../utils/error';

const NetworkError = ({ error, hasID }) => {
    if (error) {
        const statusCode = prop('status', error);
        const errorText = getNetworkErrorText(statusCode, hasID);

        return (
            <div className="wrap-sidebar">
                <div className="error-box">
                    <h1>{ errorText }</h1>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default NetworkError;
