import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Row, Col } from 'antd';

import { getVacancyId } from '../../utils/params';
import { getVacancy } from '../../actions/asyncActions';
import withSuccessPage from '../hocs/withSuccessPage';

import logo from '../../assets/img/logo-icon.png';

class ApplicationSuccess extends Component {
    render() {
        return (
            <div className="wrap-sidebar">
                <div className="head-box">
                    <Row>
                        <Col className='text-box' xs={24} lg={12}>
                            <div>
                                <h1>Thank you<br /> for your<br /> <span className="purple">referral!</span></h1>
                                <div className="desc">
                                    This is a valuable and important contribution to Accenture's success.
                                    <br />
                                    <br />
                                    The next steps of the process are in our hands.
                                </div>
                            </div>
                        </Col>
                        <Col className='logo-box' xs={24} lg={12}>
                            <img src={logo} alt=""/>
                        </Col>
                    </Row>
                    <div className='box last-box'>
                        <Row>
                            <Col xs={24} lg={24}>
                                You can track the status of your referral on <a
                                    href="https://employeereferralprogram.accenture.com/#/index"
                                    data-analytics-link-name="employeereferralprogram.accenture.com"
                                    data-analytics-content-type="nav/paginate"
                                    data-analytics-template-zone="body"
                                    data-analytics-module-name="experium-module"
                                >employeereferralprogram.accenture.com</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSuccessPage(withAsyncActions({
    getVacancy: getVacancy
        .withPayload(getVacancyId)
})(ApplicationSuccess));
