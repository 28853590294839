import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Row, Col } from 'antd';

import { getVacancyId } from '../../utils/params';
import { getVacancy } from '../../actions/asyncActions';
import withSuccessPage from '../hocs/withSuccessPage';

import logo from '../../assets/img/logo-icon.png';

class CareerSuccess extends Component {
    render() {
        return (
            <div className="wrap-sidebar">
                <div className="head-box">
                    <Row>
                        <Col className='text-box' xs={24} lg={12}>
                            <br />
                            <br />
                            <br />
                            <div>
                                <h1><span className="purple">Thank you</span><br /> for completing<br /> your application!</h1>
                            </div>
                            <br />
                            <div className="text-required">
                                We’ll be in touch if your skills and experience are a fit for one of our current open positions. If you don’t hear from us, it means that there is not a potential match at this time.
                                <br />
                                <br />
                                We will keep your application in our system.  Should a position that matches your qualifications become available, we will be in touch with you.
                            </div>
                            <br />
                            <div className="text-required">
                                Respectfully,<br/> Accenture Recruiting
                            </div>
                            <br/>
                            <br/>
                            <div>Visit us on <a
                                href="https://accenture.com/ru-ru"
                                data-analytics-link-name="accenture.com"
                                data-analytics-content-type="nav/paginate"
                                data-analytics-template-zone="body"
                                data-analytics-module-name="experium-module"
                            >accenture.com</a></div>
                        </Col>
                        <Col className='logo-box' xs={24} lg={12}>
                            <img src={logo} alt=""/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withSuccessPage(withAsyncActions({
    getVacancy: getVacancy
        .withPayload(getVacancyId)
})(CareerSuccess));
