import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { assocPath, compose, prop, path, pathOr, propEq, find, take } from 'ramda';
import Form from '@experium/findy-form';
import { Spin, Row, Col, Tooltip, Input, Form as FormComponent } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { withState } from 'recompose';
import emailMask from 'text-mask-addons/dist/emailMask'

import { REFERRAL_DOMAIN } from '../../constants/domain';
import { TRANSLATIONS } from '../../constants/translation';
import { candidateID, handlePageChange, handleFormError, handleFormSuccess } from '../../utils/adobe';
import { getVacancyId, getVacancyAccentureId } from '../../utils/params';
import { getVacancy, getDomain, postReferal } from '../../actions/asyncActions';
import NetworkError from '../NetworkError';

import logo from '../../assets/img/logo-icon.png';

const accentureEmailMask = {
    mask: val => [...(
        (val || '').replace('@accenture.com', '')
            .split('@')[0]
            .split('')
            .map(s => /[^\s]/)
    ), '[]', '@accenture.com'],
    pipe: emailMask.pipe,
};

const getRequisitionID = props => (
    props.requisitionID ? getVacancyId({ match: { params: { id: props.requisitionID } } }) : null
);

const getDomainId = compose(
    prop('id'),
    find(propEq('name', REFERRAL_DOMAIN)),
    pathOr([], ['data', '_relations', 'domains']),
);

class EmployeeLanding extends Component {
    onSubmit = values => {
        if (getRequisitionID(this.props)) {
            this.props.postReferal.dispatch(values, ({
                id: path(['data', 'id'], this.props.getVacancy),
                domain: getDomainId(this.props.getVacancy),
                search: this.props.location.search,
            }));
        }
    }

    onChangeRequisiotionId = event => {
        return this.props.setRequisitionID(`${event.target.value || ''}`.replace(/[^0-9a-zA-Z]/g, '').slice(0, 9))
    }

    render() {
        const { postReferal, getVacancy: { data, meta }, getDomain: { data : domainData, meta : domainMeta } } = this.props;
        const allowFileExtensions = path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], data);
        const requisitionID = getRequisitionID(this.props);

        return (
            <div className="wrap-sidebar">
                { postReferal.meta.success ? (
                    <h1>Application success</h1>
                ) : (
                    <Fragment>
                        <div className="head-box">
                            <Row>
                                <Col className='text-box' xs={24} lg={12}>
                                    <div>
                                      <h1>Make a <span className="purple">referral</span></h1>
                                      <div className="desc">Refer a candidate in a few easy steps</div>
                                    </div>
                                    <div>
                                        <br />
                                        <br />
                                        <FormComponent.Item
                                          label={'Requisition ID'}
                                          labelCol={{ span: 24 }}
                                          error={!requisitionID}
                                        >
                                            <input
                                                className='maskFormInput input'
                                                value={this.props.requisitionID}
                                                onChange={this.onChangeRequisiotionId}
                                                placeholder='Enter valid requisition ID'
                                            />
                                        </FormComponent.Item>
                                    </div>

                                    { meta.lastSucceedAt && (
                                        <div className="input-text">
                                            <FormComponent.Item
                                                label={'Position Name'}
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input value={meta.success && domainMeta.success ? path(['title'], data) : ''} disabled />
                                            </FormComponent.Item>
                                        </div>
                                    )}
                                </Col>
                                <Col className='logo-box' xs={24} lg={12}>
                                    <img src={logo} alt=""/>
                                </Col>
                            </Row>
                        </div>
                        { (meta.pending || domainMeta.pending) && (
                            <Spin />
                        )}
                        { requisitionID && (meta.error || domainMeta.error) ? (
                            <NetworkError error={domainMeta.lastSucceedAt ? meta.error : domainMeta.error} hasID />
                        ) : null}
                        { domainMeta.lastSucceedAt && (
                            <Form
                                fields={compose(
                                    assocPath([3, 'extra'], (
                                        <Tooltip placement="left" className='form-extra' title="You can find your Employee ID on the Portal. Click on your picture at the top right and click on My Info.">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    )),
                                    assocPath([3, 'settings', 'mask'], '99999999'),
                                    assocPath([2, 'translations', 'label', 'en'], 'Accenture email'),
                                    assocPath([2, 'settings', 'mask'], accentureEmailMask),
                                    assocPath([0, 'translations', 'label', 'en'], 'Last Name'),
                                    assocPath([1, 'translations', 'label', 'en'], 'First Name'),
                                    take(7),
                                    pathOr([], ['_relations', 'vacancyFormPreset', 'questions'])
                                )(domainData)}
                                formRender={this.formRender}
                                onSubmit={this.onSubmit}
                                onSubmitFail={() => handleFormError('employee', 'validation')}
                                opd={pathOr(data.pda, ['_relations', 'company', 'companySettings', 'pda'], data)}
                                language={'en'}
                                opdSubmitDisabled={false}
                                postFileUrl={`/api/landing/public/file/${data.id}`}
                                getFileUrl={id => `${window.location.origin}/api/file/${id}`}
                                serverErrors={postReferal.meta.error}
                                disabled={!getRequisitionID(this.props) || !meta.success || !domainMeta.success}
                                submitting={postReferal.meta.pending}
                                allowFileExtensions={allowFileExtensions}
                                translations={TRANSLATIONS}
                                htmlAttrs={{
                                    submit: {
                                        'data-analytics-link-name': 'submit',
                                        'data-analytics-content-type': 'cta',
                                        'data-analytics-template-zone': 'body',
                                        'data-analytics-module-name': 'referral application submission',
                                    },
                                }}
                            />
                        )}
                    </Fragment>
                )}
            </div>
        );
    }

    formRender = ({ fields, renderField }) => {
        return (
            <div>
                <div className="box gray-box">
                  <Row>
                      <Col span={24}>
                        <h2>About you<small>Include information about you and the role you are referring for</small>
                        <small className="text-required">All fields are required</small></h2>
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                      <Col xs={24} lg={12}>
                          {renderField(fields[1])}
                      </Col>
                      <Col xs={24} lg={12}>
                          {renderField(fields[0])}
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                      <Col xs={24} lg={12}>
                          {renderField(fields[2])}
                      </Col>
                      <Col xs={24} lg={12}>
                          {renderField(fields[3])}
                      </Col>
                  </Row>
                </div>
                <div className='box last-box'>
                  <Row>
                      <Col span={24}>
                        <h2>About your referral<small>Include here the information about your referral</small>
                        <small className="text-required">All fields are required</small></h2>
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                      <Col xs={24} lg={12}>
                          {renderField(fields[6])}
                      </Col>
                      <Col xs={24} lg={12}>
                          {renderField(fields[5])}
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
                      <Col xs={24} lg={12}>
                          {renderField(fields[4])}
                      </Col>
                  </Row>
                </div>
            </div>
        );
    }
}

export default withState('requisitionID', 'setRequisitionID', props => getVacancyAccentureId(props) || '')(
    withAsyncActions({
        postReferal: postReferal
            .withOptions()
            .withSuccessHandler(({ history, location }) => {
                handleFormSuccess('employee');
                handlePageChange({
                    page: {
                        pageInfo: {
                            pageName: 'exp:experium-employeereferral-success',
                        }
                    },
                });
                history.push(`${location.pathname}/success`.replace('//', '/'));
            })
            .withErrorHandler(() => {
                handleFormError('employee', 'request');
            }),
        getVacancy: getVacancy
            .withPayload(getRequisitionID)
            .withSuccessHandler(props => {
                handlePageChange({
                    page: {
                        pageInfo: {
                            pageName: 'exp:experium-employeereferral',
                        }
                    },
                    job: {
                        jobID: props.requisitionID,
                        jobTitle: path(['data', 'title'], props.getVacancy)
                    },
                    user: {
                        candidateID,
                    }
                });
                })
            .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true }),
        getDomain: getDomain
            .withPayload(({ getVacancy }) => ({
                company: pathOr('accenture', ['data', 'company'], getVacancy),
                domain: getDomainId(getVacancy) || 'error',
            }))
            .withOptions({ dispatchOnUpdate: true })
    })(EmployeeLanding)
);
