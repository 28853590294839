import React from 'react';
import { withAsyncActions } from 'react-async-client';

import { postApplicant, postReferal, postReferalApplicant } from '../../actions/asyncActions';

export default Component => withAsyncActions({
    postApplicant: postApplicant,
    postReferal: postReferal,
    postReferalApplicant: postReferalApplicant,
})(props => (props.postApplicant.meta.success || props.postReferal.meta.success || props.postReferalApplicant.meta.success || props) ? (
    <Component {...props} />
) : null );
