import { NOT_FOUND, WRONG_LINK, WRONG_ID, WRONG_TOKEN, SERVER_ERROR } from '../constants/errors';

export const getNetworkErrorText = (statusCode, hasID) => {
    switch (statusCode) {
        case 400:
            return hasID ? WRONG_ID : WRONG_LINK;
        case 403:
            return WRONG_TOKEN;
        case 404:
            return NOT_FOUND;
        default:
            return SERVER_ERROR;
    }
};
