import React from 'react';

export const WRONG_TOKEN = (
    <div>
        We believe you have already applied for this position.<br /><br />
        <small>We will be in touch if we need more information about you.</small><br />
        <small>Keep an eye on your mailbox.</small>
    </div>
);
export const WRONG_LINK = 'Wrong Link';
export const WRONG_ID = 'Wrong Requisition ID';
export const NOT_FOUND = 'Not found';
export const SERVER_ERROR = 'Server error';
