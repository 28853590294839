import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import CareerLanding from './pages/CareerLanding';
import RecommendedLanding from './pages/RecommendedLanding';
import EmployeeLanding from './pages/EmployeeLanding';
import ApplicationSuccess from './pages/ApplicationSuccess';
import ReferralSuccess from './pages/ReferralSuccess';
import CareerSuccess from './pages/CareerSuccess';

import Layout from './Layout';
import NoMatch from './NoMatch';

class Routes extends Component {
    render() {
        return (
            <Layout>
                <Switch>
                    <Route path='/' exact component={NoMatch} />

                    <Route path='/CareerSite/:id/success' component={CareerSuccess} />
                    <Route path='/CareerSite/:id' component={CareerLanding} />
                    <Route path='/EmployeeReferral/:id/success' component={ReferralSuccess} />
                    <Route path='/EmployeeReferral/success' component={ReferralSuccess} />
                    <Route path='/EmployeeReferral/:id' component={EmployeeLanding} />
                    <Route path='/EmployeeReferral' component={EmployeeLanding} />

                    <Route path='/recommended/:id/:token/success' component={ApplicationSuccess} />
                    <Route path='/recommended/:id/:token' component={RecommendedLanding} />
                    <Route component={NoMatch} />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(Routes);
