import { mergeDeepRight } from 'ramda';
import uniqid from 'uniqid';

export const candidateID = uniqid();

export const handlePageChange = (newPage) => {
    try {
        window.dataModel = mergeDeepRight(window.dataModel, newPage);

        if (!window.dataModel.enableLaunch) {
            window.dataModel.enableLaunch = true;
        }

        window.$("body").trigger("custom-pageview");
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const handleFormError = (type, error) => {
    try {
        console.log('Event Form Error');
        return window.$('body').trigger(window.dataForm.Events.Form.Error);
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const handleFormSuccess = (type) => {
    try {
        console.log('Event Form Success');
        return window.$('body').trigger(window.dataForm.Events.Form.Success);
    } catch (e) {
        console.error(e);
        return false;
    }
};
