import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
import '@experium/findy-form/lib/styles/index.css';
import './styles/index.less';

import { setBaseUrl, setLanguage } from './utils/http';
import { provideResponseInterceptors, provideRequestInterceptors } from './utils/httpInterceptors';
import rollbarInit from './utils/rollbarInit';

import App from './components/App';
import configureStore from './store/configureStore';

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: '8f47b71c320b4e5d907f062cd3eba1fc',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });
}

provideResponseInterceptors();
provideRequestInterceptors();
setBaseUrl();
setLanguage();

const store = configureStore();
const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(<App store={store} />, MOUNT_NODE);
