export const TRANSLATIONS = {
    en: {
        translation: {
            'opdLabelCustom': 'I accept the data privacy policy',
            'errors.phone': 'Please enter a valid phone number',
            'errors.email': 'Please enter a valid e-mail address',
            'errors.emailChars': 'Please enter a valid e-mail address (valid characters - letters numbers _ - .)',
        }
    },
};
